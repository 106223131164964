export default {
  namespaced: true,
  state: {
    accessToken: '',
    client: '',
    uid: '',
  },
  getters: {
    loggedIn: (state) => {
      return typeof state.accessToken === 'string' && state.accessToken.length > 0
    },
    headerAuth: (state) => {
      const auth_header = JSON.parse(JSON.stringify(state))
      auth_header['access-token'] = auth_header['accessToken']
      delete auth_header.accessToken
      return auth_header
    },
    current_user_id: (state) => {
      const auth_header = JSON.parse(JSON.stringify(state))
      return auth_header['uid']
    },
  },
  mutations: {
    create(state, data) {
      state.accessToken = data.accessToken
      state.client = data.client
      state.uid = data.uid
    },
    destroy(state) {
      state.accessToken = ''
      state.client = ''
      state.uid = ''
    },
  },
  actions: {
    createAuth(context, data) {
      context.commit('create', data)
    },
    destroyAuth(context) {
      context.commit('destroy')
    },
  },
}
