<template>
  <div v-if="productImages.length > 0" class="swiperWrapper">
    <swiper ref="mainSwiper" :options="swiperOptions">
      <swiper-slide v-for="productImage in productImages" :key="productImage.id">
        <img :src="productImage.fileUrl" />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <div class="swiper-pagination" slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { deserialize } from 'deserialize-json-api'
import 'swiper/css/swiper.css'

export default {
  name: 'carrousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    productImagesString: String,
  },
  data() {
    return {
      productImages: [],
      swiperOptions: {
        loop: true,
        slidesPerView: 1,
        initialSlide: 0,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
          renderBullet: (index, className) => {
            return (
              '<img class="paginationThumb ' + className + '" src="' + this.productImages[index].fileUrl + '"></img>'
            )
          },
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mainSwiper.$swiper
    },
  },
  mounted() {
    const productImagesJson = JSON.parse(this.productImagesString)
    this.productImages = Object.assign([], deserialize(productImagesJson).data)
  },
}
</script>
<style>
.swiperWrapper {
  text-align: center;
}
.swiper-pagination {
  position: static;
  margin: 0 0 20px 0;
  display: flex;
  flex-flow: wrap;
}
.swiper-slide {
  margin: auto auto;
}
.paginationThumb {
  width: 40px;
  height: 40px;
  border-radius: 0%;
  opacity: 1;
  margin: 0 10px 0 0;
}
.swiper-pagination-bullet-active {
  border: black solid 1px;
}
.swiper-button-prev,
.swiper-button-next {
  color: #999;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .swiper-pagination {
    position: static;
    margin: 0 10px 10px 10px;
    display: flex;
    flex-flow: wrap;
  }
  .paginationThumb {
    width: 30px;
    height: 30px;
    border-radius: 0%;
    opacity: 1;
    margin: 0 10px 0 0;
  }
}
</style>
