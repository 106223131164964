export default {
  namespaced: true,
  // state: {
  // memberLoggedIn: null,
  // },
  getters: {
    memberLoggedIn: () => {
      const cookieData = {}
      let tmpCookieDataArray = document.cookie.split(';')
      tmpCookieDataArray.forEach(function (value) {
        let content = value.replace(' ', '').split('=')
        if (content[0] == 'client') {
          cookieData['client'] = content[1]
        } else if (content[0] == 'uid') {
          cookieData['uid'] = unescape(content[1])
        } else if (content[0] == 'access_token') {
          cookieData['access-token'] = content[1]
        }
      })

      return cookieData['access-token'] && cookieData['uid'] && cookieData['client']
      // if (cookieData['access-token'] && cookieData['uid'] && cookieData['client']) {
      // result = true
      // }
      // return result
    },
  },
}
