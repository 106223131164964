import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import store from '../stores'
import HeaderSocialBtns from '../components/media/common/HeaderSocialBtns.vue'
import HeaderLogin from '../components/media/common/HeaderLogin.vue'
import HeaderLoginMobile from '../components/media/common/HeaderLoginMobile.vue'
import PostFavoriteBtn from '../components/media/PostFavoriteBtn.vue'
import ProductFavoriteBtn from '../components/media/ProductFavoriteBtn.vue'
import ProductImagesSwiper from '../components/media/ProductImagesSwiper.vue'
import TagList from '../components/media/TagList.vue'
import TagListProduct from '../components/media/TagListProduct.vue'
import MemberHistory from '../components/media/MemberHistory.vue'
import VueAxiosPlugin from '../plugins/vue-axios'
import MarkdownPreview from '../components/media/MarkdownPreview.vue'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import '../stylesheets/folk.scss'
import 'bootstrap/scss/bootstrap-grid'

const toKebabCase = function (str) {
  if (typeof str !== 'string') return str

  str = str.replace(/^ *?[A-Z]/, function (allStr) {
    return allStr.toLowerCase()
  })
  str = str.replace(/_/g, '-')
  str = str.replace(/ *?[A-Z]/g, function (allStr, i) {
    return '-' + allStr.replace(/ /g, '').toLowerCase()
  })
  return str
}
document.addEventListener('DOMContentLoaded', () => {
  // Add it here, if you create a new component.
  const componentsToRegister = {
    HeaderLogin,
    HeaderLoginMobile,
    PostFavoriteBtn,
    HeaderSocialBtns,
    ProductImagesSwiper,
    TagList,
    TagListProduct,
    ProductFavoriteBtn,
    MemberHistory,
    MarkdownPreview,
  }

  // Initialization of elements with `.vue-mount-area` class.
  const classElements = document.getElementsByClassName('vue-mount-area')
  Array.prototype.forEach.call(classElements, (element, i) => {
    new Vue({
      el: element,
      components: componentsToRegister,
      replace: false,
    })
  })

  // Initialization of element specified by tag name.
  let component, tagName, tagElements
  Object.keys(componentsToRegister).forEach(function (key) {
    component = componentsToRegister[key]
    tagName = toKebabCase(key)
    tagElements = document.getElementsByTagName(tagName)
    Array.prototype.forEach.call(tagElements, (element, i) => {
      new Vue({
        el: element,
        replace: true,
        render: (h) => h(component),
      })
    })
  })
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceworker.js')
    .catch(function(error) {
      console.log('Service worker registration failed: ', error);
    });
}
