<template>
  <div></div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    postId: Number,
  },
  mounted() {
    axios.put(`/members/histories/${this.postId}`).then()
  },
}
</script>
