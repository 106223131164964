<template>
  <div>
    <a
      href="https://store.folk-media.com/"
      class="l-header__shoppingBtn l-header__shoppingBtn"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i class="far fa-shopping-bag"></i>
      <p class="l-header__socialText">お買い物</p>
    </a>
  </div>
</template>

<script>
import store from '../../../stores'

export default {
  data() {
    return {
      isSignedIn: false,
      favoriteUrl: '/members/favorites',
    }
  },
  mounted() {
    this.isSignedIn = store.getters['member/memberLoggedIn']
  },
  methods: {
    isMyFavoritesPage() {
      return location.pathname.includes('/members/favorites')
    },
    isMyHistoriesPage() {
      return location.pathname.includes('/members/histories')
    },
  },
}
</script>
