import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

import auth from './modules/auth'
import message from './modules/message'
import member from './modules/member'
import tag from './modules/media/tag'
import top from './modules/media/top'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    message: message,
    member: member,
    // media
    tag: tag,
    top: top,
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: 'FolkManagement',
      paths: ['auth.accessToken', 'auth.client', 'auth.uid', 'member.accessToken', 'member.client', 'member.uid'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 1 / 2 }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
})
