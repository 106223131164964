<template>
  <div class="headerLogin">
    <template v-if="isSignedIn">
      <a href="/members/favorites" class="l-header__socialBtn l-header__socialBtn--pcOnly">
        <i class="fal fa-heart"></i>
        <p class="l-header__socialText">お気に入り</p>
      </a>
      <a href="/members/histories" class="l-header__socialBtn l-header__socialBtn--pcOnly">
        <i class="fal fa-history"></i>
        <p class="l-header__socialText">閲覧履歴</p>
      </a>
      <a @click="logoutMember" class="l-header__socialBtn l-header__socialBtn--pcOnly">
        <i class="far fa-sign-out"></i>
        <p class="l-header__socialText">ログアウト</p>
      </a>
    </template>
    <template v-else>
      <a href="/members/sign_up" class="l-header__socialBtn l-header__socialBtn--pcOnly">
        <i class="fas fa-plus"></i>
        <p class="l-header__socialText">新規登録</p>
      </a>
      <a href="/members/sign_in" class="l-header__socialBtn l-header__socialBtn--pcOnly">
        <i class="far fa-sign-in"></i>
        <p class="l-header__socialText">ログイン</p>
      </a>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import store from 'stores'
import signInText from 'images/sp-hd-sign-in-logo.png'
import signUpText from 'images/sp-hd-sign-up-logo.png'

export default {
  data() {
    return {
      isSignedIn: false,
      sessionData: {
        'access-token': '',
        client: '',
        uid: '',
      },
      signUpText: signUpText,
      signInText: signInText,
    }
  },
  mounted() {
    this.isSignedIn = store.getters['member/memberLoggedIn']
  },
  methods: {
    logoutMember() {
      axios.delete('/members/sign_out').finally(() => (location.href = '/members/sign_in'))
    },
  },
}
</script>
