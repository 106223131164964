<template>
  <ul class="l-navSidebar__innerMenu sidebarMenuInner">
    <div class="l-navSidebar__innerMenu--header" />
    <div class="l-navSidebar__innerMenu--content">
      <template v-if="isSignedIn">
        <li class="l-navSidebar__sectionTitle">会員登録</li>
        <li class="sp_member_favorite">
          <a href="/members/favorites"><i class="fal fa-heart"></i>お気に入り</a>
        </li>
        <li class="sp_member_history">
          <a href="/members/histories"><i class="fal fa-history"></i>閲覧履歴</a>
        </li>
        <li>
          <a @click="logoutMember"> <i class="fal fa-sign-out"></i>ログアウト </a>
        </li>
        <li>
          <a href="https://store.folk-media.com/"> <i class="fal fa-shopping-cart"></i>お買い物 </a>
        </li>
      </template>
      <template v-else>
        <li class="l-navSidebar__sectionTitle">会員登録</li>
        <li>
          <a href="/members/sign_in"> <i class="fal fa-sign-in"></i>ログイン </a>
        </li>
        <li>
          <a href="https://store.folk-media.com/"> <i class="fal fa-shopping-cart"></i>お買い物 </a>
        </li>
        <li>
          <a href="/members/sign_up"> <i class="fal fa-user-plus"></i>新規登録 </a>
        </li>
      </template>
      <ul
        class="custom-menu-item"
        @click="onClickGaAction(['send', 'event', 'sp_top_scroll_nav', 'click', '/?5743897534'])"
      >
        <li class="l-navSidebar__sectionTitle">カテゴリー</li>
        <HeaderNavSidebarMenuItem :item="category" v-for="(category, index) in categories" :key="index" />
        <div class="l-navSidebar__parentMenu" :class="{ on: this.on }">
          <li class="l-navSidebar__sectionTitleExpandable" @click="onMenuClick">ブランド</li>
          <div class="l-navSidebar__childMenu">
            <HeaderNavSidebarMenuItem :item="brand" v-for="(brand, bIndex) in brands" :key="bIndex" />
          </div>
        </div>
        <div class="l-navSidebar__parentMenu" :class="{ on: this.onSNS }">
          <li class="l-navSidebar__sectionTitleExpandable" @click="onMenuClickSNS">SNS</li>
          <div class="l-navSidebar__childMenu">
            <li>
              <a
                href="https://www.instagram.com/folk_jp/"
                target="_blank"
                @click="onClickGaAction(['send', 'event', 'header_instagram_badge', 'click', '2931978'])"
                data-wpel-link="external"
                rel="noopener noreferrer"
                ><i class="fab fa-instagram" aria-hidden="true"></i>インスタグラム</a
              >
            </li>
            <li>
              <a
                href="https://www.pinterest.jp/folk_media/"
                target="_blank"
                @click="onClickGaAction(['send', 'event', 'header_pinterest_badge', 'click', '2931978'])"
                data-wpel-link="external"
                rel="noopener noreferrer"
                ><i class="fab fa-pinterest-p"></i>ピンタレスト</a
              >
            </li>
            <li>
              <a
                href="https://twitter.com/folk_media_"
                @click="onClickGaAction(['send', 'event', 'header_twitter_badge', 'click', '2931978'])"
                data-wpel-link="external"
                target="_blank"
                rel="noopener noreferrer"
                ><img :src="require('../../../images/logo-black.png')" alt="Logo" />エックス</a
              >
            </li>
          </div>
        </div>
      </ul>
    </div>
  </ul>
</template>

<script>
import axios from 'axios'
import store from '../../../stores'
import HeaderNavSidebarMenuItem from './HeaderNavSidebarMenuItem'

export default {
  components: {
    HeaderNavSidebarMenuItem,
  },
  props: {
    categories: Array,
    brands: Array,
  },
  data() {
    return {
      isSignedIn: false,
      sessionData: {
        'access-token': '',
        client: '',
        uid: '',
      },
      activeCategory: '',
      on: false,
      onSNS: false,
    }
  },
  mounted: function () {
    this.isSignedIn = store.getters['member/memberLoggedIn']
  },
  methods: {
    logoutMember() {
      axios.delete('/members/sign_out').finally(() => (location.href = '/members/sign_in'))
    },
    toggleMenu(category) {
      if (this.activeCategory === category) {
        this.activeCategory = ''
      } else {
        this.activeCategory = category
      }
    },
    onClickGaAction(args) {
      window.ga(...args)
    },
    onMenuClick() {
      this.on = !this.on
    },
    onMenuClickSNS() {
      this.onSNS = !this.onSNS
    },
  },
}
</script>
