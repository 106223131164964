import axios from 'axios'

export default {
  namespaced: true,
  state: {
    trendTagsPosts: [],
    trendTagsProducts: [],
    popularCategoriesPosts: [],
    popularCategoriesProducts: [],
    popularBrandsPosts: [],
    popularBrandsProducts: [],
    navigationCategories: [],
    footerCategories: [],
    footerBrands: [],
    menuBrands: [],
    footerPages: [],
  },
  mutations: {
    setTrendTagsPosts(state, tags) {
      state.trendTagsPosts = tags
    },
    setTrendTagsProducts(state, tags) {
      state.trendTagsProducts = tags
    },
    setPopularCategoriesPosts(state, categories) {
      state.popularCategoriesPosts = categories
    },
    setPopularCategoriesProducts(state, categories) {
      state.popularCategoriesProducts = categories
    },
    setPopularBrandsPosts(state, brands) {
      state.popularBrandsPosts = brands
    },
    setPopularBrandsProducts(state, brands) {
      state.popularBrandsProducts = brands
    },
    setNavigationCategories(state, categories) {
      state.navigationCategories = categories
    },
    setFooterCategories(state, categories) {
      state.footerCategories = categories
    },
    setFooterBrands(state, brands) {
      state.footerBrands = brands
    },
    setMenuBrands(state, brands) {
      state.menuBrands = brands
    },
    setFooterPages(state, pages) {
      state.footerPages = pages
    },
  },
  actions: {
    fetchLayoutTags(context) {
      axios.get('/api/media_front/general_items').then((response) => {
        const {
          navigation_categories,
          trend_tags_posts,
          trend_tags_products,
          popular_categories_posts,
          popular_categories_products,
          popular_brands_posts,
          popular_brands_products,
          footer_categories,
          footer_brands,
          menu_brands,
          footer_pages,
        } = response.data
        context.commit('setTrendTagsPosts', trend_tags_posts)
        context.commit('setTrendTagsProducts', trend_tags_products)
        context.commit('setPopularCategoriesPosts', popular_categories_posts)
        context.commit('setPopularCategoriesProducts', popular_categories_products)
        context.commit('setPopularBrandsPosts', popular_brands_posts)
        context.commit('setPopularBrandsProducts', popular_brands_products)
        context.commit('setNavigationCategories', navigation_categories)
        context.commit('setFooterCategories', footer_categories)
        context.commit('setFooterBrands', footer_brands)
        context.commit('setMenuBrands', menu_brands)
        context.commit('setFooterPages', footer_pages)
      })
    },
  },
}
