<template>
  <li class="l-navSidebar__categoryList" :class="{ on: this.on }">
    <p v-if="item.terms != null" class="l-navSidebar__category" @click="onCategoryClick">
      <i :class="item.icon"></i>
      {{ item.name }}
    </p>
    <a :href="item.url" v-else class="l-navSidebar__brandMenu">
      {{ item.name }}
    </a>
    <ul v-if="item.terms != null" class="l-navSidebar__categoryMenu">
      <li><a :href="item.url">すべて</a></li>
      <li v-for="(term, termIndex) in item.terms" :key="termIndex">
        <a :href="term.url">{{ term.name }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'HeaderNavSidebarMenuItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      on: false,
    }
  },
  methods: {
    onCategoryClick() {
      this.on = !this.on
    },
  },
}
</script>
