export default {
  namespaced: true,
  state: {
    text: '',
    color: '',
    display: false,
  },
  getters: {
    snackbarDisplay: (state) => {
      return state.display && !!state.text
    },
    snackbarColor: (state) => {
      if (!state.color) {
        return 'grey'
      } else {
        return state.color
      }
    },
    snackbarText: (state) => {
      return state.text
    },
  },
  mutations: {
    create(state, data) {
      state.text = data.text
      state.color = data.color
      state.display = true
    },
    destroy(state) {
      state.text = ''
      state.color = ''
      state.display = false
    },
  },
  actions: {
    createMessage(context, data) {
      context.commit('create', data)
    },
    destroyMessage(context) {
      context.commit('destroy')
    },
  },
}
