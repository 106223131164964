import axios from 'axios'

export default {
  namespaced: true,
  state: {
    topPickSpPost: null,
    professionalAuthors: [],
    features: [],
    newPosts: [],
    partnerShipAuthors: [],
    pickSpPosts: [],
    widgetsInBottom: [],
    widgetsInHead: [],
    widgetsTopBody: [],
  },
  mutations: {
    setTopPickSpPost(state, topPick) {
      state.topPickSpPost = topPick
    },
    setProfessionalAuthors(state, authors) {
      state.professionalAuthors = authors
    },
    setFeatures(state, features) {
      state.features = features
    },
    setNewPosts(state, posts) {
      state.newPosts = posts
    },
    setPartnerShipAuthors(state, authors) {
      state.partnerShipAuthors = authors
    },
    setPickSpPosts(state, posts) {
      state.pickSpPosts = posts
    },
    setWidgetsInBottom(state, widgets) {
      state.widgetsInBottom = widgets
    },
    setWidgetsInHeader(state, widgets) {
      state.widgetsInHead = widgets
    },
    setWidgetsTopBody(state, widgets) {
      state.widgetsTopBody = widgets
    },
  },
  actions: {
    fetchLayoutTops(context) {
      axios.get('/api/media_front/top').then((response) => {
        const {
          top_pick_sp_post,
          professional_authors,
          features,
          new_posts,
          partner_ship_authors,
          pick_sp_posts,
          widgets_in_bottom,
          widgets_in_head,
          widgets_top_body,
        } = response.data
        context.commit('setTopPickSpPost', top_pick_sp_post)
        context.commit('setProfessionalAuthors', professional_authors.data)
        context.commit('setFeatures', features.data)
        context.commit('setNewPosts', new_posts)
        context.commit('setPartnerShipAuthors', partner_ship_authors.data)
        context.commit('setPickSpPosts', pick_sp_posts)
        context.commit('setWidgetsInBottom', widgets_in_bottom.data)
        context.commit('setWidgetsInHeader', widgets_in_head.data)
        context.commit('setWidgetsTopBody', widgets_top_body.data)
      })
    },
  },
}
