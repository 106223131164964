<template>
  <ul class="c-brandHeader__tagList">
    <li v-for="(item, index) in tagList" :key="index" v-show="index >= 10 ? show : true">
      <a v-bind:href="getLink(item)" :title="item.name">{{ item.name }}</a>
    </li>
    <li v-if="tagList.length >= 10">
      <a class="c-extendItem" @click="showMore">
        <i :class="icon"></i>
        <span>{{ text }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tagListString: String,
  },
  data() {
    return {
      tagList: [],
      text: 'もっと表示する',
      icon: 'fas fa-angle-double-right',
      show: false,
    }
  },
  mounted() {
    this.tagList = JSON.parse(this.tagListString)
  },
  methods: {
    showMore() {
      if (this.show) {
        this.show = false
        this.text = 'もっと表示する'
        this.icon = 'fas fa-angle-double-right'
      } else {
        this.show = true
        this.text = '表示を減らす'
        this.icon = 'fas fa-angle-double-left'
      }
    },
    getLink(term) {
      if (term.term_type === 'category') return '/product_categories/' + encodeURIComponent(term.slug)
      else if (term.term_type === 'brand') return '/product_brands/' + encodeURIComponent(term.slug)
      else if (term.term_type === 'tag') return '/product_tags/' + encodeURIComponent(term.slug)
    },
  },
}
</script>
