import snakecaseKeys from 'snakecase-keys'

const VueAxiosPlugin = {}
export default VueAxiosPlugin.install = function (Vue, { axios, csrfToken, store, router }) {
  if (!axios) {
    return
  }

  axios.interceptors.request.use((request) => {
    let store_auth = store.getters['auth/headerAuth']

    if (!request.headers['access-token']) {
      request.headers['access-token'] = store_auth['access-token']
    }
    if (!request.headers['client']) {
      request.headers['client'] = store_auth['client']
    }
    if (!request.headers['uid']) {
      request.headers['uid'] = store_auth['uid']
    }
    if (typeof csrfToken === 'function') {
      request.headers['X-CSRF-Token'] = csrfToken()
    }
    if (request.data && !(request.data instanceof FormData)) {
      request.data = snakecaseKeys(request.data)
    }
    return request
  })

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.status === 401) {
        let message = '再度、認証をしてください。'
        if (router.currentRoute.path == '/login') {
          message = '認証情報が間違っています。'
        }
        store.dispatch('auth/destroyAuth')
        store.dispatch({
          type: 'message/createMessage',
          color: 'warning',
          text: message,
        })
      } else if (error.response.status === 422) {
        store.dispatch({
          type: 'message/createMessage',
          color: 'error',
          text: '処理ができません。',
        })
      } else if (error.response.status === 500) {
        store.dispatch({
          type: 'message/createMessage',
          color: 'error',
          text: '一時的なエラーが発生しました。',
        })
      }
      return Promise.reject(error)
    }
  )

  Vue.axios = axios

  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      },
    },
  })
}
