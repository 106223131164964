<template>
  <div class="p-favorite">
    <a class="p-favoriteBtn p-favoriteBtn--done" @click="unFavorite" v-if="isFavorited">
      <i class="far fa-heart"></i>
      <span class="p-headerFavorite__text">お気に入り済み</span>
    </a>
    <a class="p-favoriteBtn" @click="favorite" v-else>
      <i class="far fa-heart"></i>
      <span class="p-headerFavorite__text">お気に入りにする</span>
    </a>
  </div>
</template>
<script>
import store from '../../stores'
import axios from 'axios'

export default {
  data() {
    return {
      isSignedIn: false,
      isFavorited: false,
    }
  },
  mounted() {
    this.isSignedIn = store.getters['member/memberLoggedIn']
    if (this.isSignedIn) {
      axios
        .get('/products/' + this.productId + '/favorite.json')
        .then(() => {
          this.isFavorited = true
        })
        .catch(() => {
          this.isFavorited = false
        })
    }
  },
  props: {
    productId: String,
  },
  methods: {
    unFavorite() {
      axios.delete('/products/' + this.productId + '/favorite.json').then(() => {
        this.isFavorited = false
      })
    },
    favorite() {
      if (this.isSignedIn) {
        axios.post('/products/' + this.productId + '/favorite.json').then(() => {
          this.isFavorited = true
        })
      } else {
        window.open('/members/sign_in')
      }
    },
  },
}
</script>
