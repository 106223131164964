<template>
  <div>
    <a class="p-favoriteBtn p-favoriteBtn--done" @click="unFavorite" v-if="isFavorited">
      <i class="far fa-heart"></i>
      <span class="p-headerFavorite__text">お気に入り済み</span>
    </a>
    <a class="p-favoriteBtn" @click="favorite" v-else>
      <i class="far fa-heart"></i>
      <span class="p-headerFavorite__text">お気に入りにする</span>
    </a>
  </div>
</template>
<script>
import store from '../../stores'
import axios from 'axios'

export default {
  data() {
    return {
      isSignedIn: false,
      isFavorited: false,
      postId: null,
    }
  },
  mounted() {
    this.isSignedIn = store.getters['member/memberLoggedIn']
    this.postId = location.pathname.match(/\d+/)[0]
    if (this.isSignedIn) {
      axios
        .get('/posts/' + this.postId + '/favorite.json')
        .then(() => {
          this.isFavorited = true
        })
        .error(() => {
          this.isFavorited = false
        })
    }
  },
  methods: {
    unFavorite() {
      axios.delete('/posts/' + this.postId + '/favorite.json').then(() => {
        this.isFavorited = false
      })
    },
    favorite() {
      if (this.isSignedIn) {
        axios.post('/posts/' + this.postId + '/favorite.json').then(() => {
          this.isFavorited = true
        })
      } else {
        window.open('/members/sign_in')
      }
    },
  },
}
</script>
